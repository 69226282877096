<template>
  <main-layout
    :key="projectId"
    :entity-name="name"
    :entity-logo="logo"
    :parent-entity-name="project.clusterId ? clusterName : organizationName"
    :parent-entity-route="parentEntityRoute"
    :sidebar-items="filteredSidebarItems"
  >
    <router-view :key="($route.params.projectId || '')" />
    <publish-dialogs v-for="(type, i) in dialogs" :key="i" :type="type" />
    <open-competition-missing-data-dialog
      v-if="showOpenCompetitionMissingDataDialog"
      @close-dialog-open-competition-missing-data="showOpenCompetitionMissingDataDialog = false"
    />
    <confirm-open-competition-dialog
      v-if="showConfirmOpenCompetitionDialog"
      @close-dialog-confirm-open-competition="showConfirmOpenCompetitionDialog = false"
      @confirm-open-competition="onConfirmOpenCompetition"
    />
  </main-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import getDateRange from '@/utils/getDateRange'
import MainLayout from '@/components/MainLayout'

export default {
  name: 'Project',
  components: {
    MainLayout,
    PublishDialogs: () => import('@/modules/project/mixins/PublishDialogs.js'),
    OpenCompetitionMissingDataDialog: () => import('@/modules/project/OpenCompetitionMissingDataDialog'),
    ConfirmOpenCompetitionDialog: () => import('@/modules/project/ConfirmOpenCompetitionDialog'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      isDataLoaded: false,
      dialogs: [
        'MissingData',
      ],
      showConfirmOpenCompetitionDialog: false,
      showOpenCompetitionMissingDataDialog: false,
    }
  },
  computed: {
    ...mapGetters({ organization: 'organization/data' }),
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ isUserCoach: 'project/isUserCoach' }),
    ...mapGetters({ isShortTerm: 'project/isShortTerm' }),
    ...mapGetters({ isLongTerm: 'project/isLongTerm' }),
    ...mapGetters({ isCompetition: 'project/isCompetition' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ cluster: 'cluster/data' }),
    ...mapGetters('project', ['isGuild']),
    ...mapGetters({ requirementStepsForPublish: 'project/requirementStepsForPublish' }),
    ...mapGetters({ requirementStepsForOpenCompetition: 'project/requirementStepsForOpenCompetition' }),
    ...mapGetters({ isSubscriberApprovalRequired: 'project/isSubscriberApprovalRequired' }),
    ...mapGetters({ requiresForm: 'project/hasFormFeatures' }),
    ...mapGetters({ sidebarItems: 'project/sidebarItems' }),
    name({ project }) { return project.name },
    logo({ project }) { return project.logo || '' },
    organizationName({ organization }) { return organization.name },
    clusterName: ({ cluster }) => cluster?.name,
    parentEntityRoute: ({ organizationId, project }) => project.clusterId
      ? { name: 'cluster-projects', params: { organizationId, clusterId: project.clusterId } }
      : { name: 'organization', params: { organizationId } },
    filteredSidebarItems({ sidebarItems, isShortTerm, isSubscriberApprovalRequired, isGuild }) {
      return sidebarItems
        .filter(item => item.id === 'waiver' ? this.project?.features?.terms : true)
        .filter(item => item.id === 'form' ? this.project?.features?.form : true)
        .filter(item => item.id === 'enrollments' ? isSubscriberApprovalRequired : true)
        .filter(item => item.id === 'payments' ? this.project?.features?.price : true)
        .filter(item => item.id === 'categories' ? this.isAcademy : true)
        .filter(item => item.id === 'publish' ? !this.project.published : true)
        .filter(item => item.id === 'bans' ? this.project?.features?.hasMatchEvents : true)
        .filter(item => item.id === 'openCompetition' ? (this.project.published && !this.project.isOpen) : true)
        .filter(item => item.id === 'competitionMatchLocations' ? this.project?.features.hasMatchLocations : true)
        .map(item => item.id === 'publish' ? { ...item, action: this.publish } : item)
        .map(item => item.id === 'openCompetition' ? { ...item, action: this.openCompetition } : item)
        .map(item => item.id === 'players' ? { ...item, name: `project.sections.players-${this.project?.type}` } : item)
        .concat(isShortTerm
          ? [{
            id: 'all-activities',
            name: 'All',
            route: { name: 'project-calendar', params: { date: 'all' } },
            conditions: 'all',
            parentId: 'activities',
          }, ...this.dateItems]
          : [])
    },
    dateItems() {
      const timezone = this.$store.getters['project/data'].timezone
      const startDate = this.$store.getters['project/data']?.date?.start.toDate() || null
      const endDate = this.$store.getters['project/data']?.date?.end.toDate() || null
      const dateRange = getDateRange(startDate, endDate)
      return dateRange.map((date, i) => {
        const localizedDate = DateTime.fromJSDate(date, { zone: timezone })
        const day = localizedDate.day.toString().padStart(2, '0')
        const month = localizedDate.month.toString().padStart(2, '0')
        const year = localizedDate.year.toString()
        const id = `Day ${i + 1} - ${day}/${month}/${year.slice(0, 2)}`
        return {
          id,
          name: id,
          parentId: 'activities',
          conditions: 'all',
          route: {
            name: 'project-calendar',
            params: { date: `${year}-${month}-${day}` },
            meta: {
              title: () => `Day ${i + 1} - ${day}/${month}/${year.slice(0, 2)}`,
              headerTitle: () => `Day ${i + 1} - ${day}/${month}/${year.slice(0, 2)}`,
            },
          },
        }
      })
    },
  },
  methods: {
    async publish() {
      const { organizationId, projectId, requirementStepsForPublish } = this
      if (requirementStepsForPublish.length) {
        this.$store.commit('project/toggleDataDialog', true)
      } else {
        this.runAsync(() => this.$store.dispatch('project/publish', { organizationId, projectId }))
      }
    },
    openCompetition() {
      if (this.requirementStepsForOpenCompetition.length) {
        this.showOpenCompetitionMissingDataDialog = true
      } else {
        this.showConfirmOpenCompetitionDialog = true
      }
    },
    async onConfirmOpenCompetition() {
      const { organizationId, projectId } = this
      await this.runAsync(() => this.$store.dispatch('project/openCompetition', { organizationId, projectId }))
      this.showConfirmOpenCompetitionDialog = false
    },
  },
}
</script>
