export default (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000
  const totalDays = Math.ceil((endDate - startDate) / oneDay)
  const dates = [...Array(totalDays).keys()].map(dayNumber => {
    const newDate = new Date(startDate.getTime())
    newDate.setDate(startDate.getDate() + dayNumber)
    return newDate
  })
  return dates
}
